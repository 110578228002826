











































import { Component, PropSync, Vue } from 'vue-property-decorator';
import DeleteAccountSuccessViewModel
  from '@/vue-app/view-models/components/user-profile/user-profile-tabs/user-profile-delete-account/delete-account-success-view-model';

@Component({ name: 'DeleteAccountSuccess' })
export default class DeleteAccountSuccess extends Vue {
  @PropSync('accreditationDate', { type: String, required: true })
  accreditation_date!: string;

  delete_account_success_view_model = Vue.observable(
    new DeleteAccountSuccessViewModel(this),
  );
}

